@import '@app/styles/variables';
@import '@app/styles/mixins';

.table-payment-container {
  border-radius: 20px;
  box-shadow: $box-shadow;
  margin-bottom: 3rem;
  width: 100%;

  .navbar-item-button {
    background-color: transparent;
    border: none;

    &:first-child {
      .navbar-link:hover {
        background-color: transparent;
      }
    }
  }

  &.table-payment-container-inner {
    margin-bottom: 0;
    box-shadow: none;
  }

  .icon-ellipsis-vertical {
    overflow: visible;
    width: 0.25rem;
    box-sizing: content-box;
  }

  .table {
    table-layout: fixed;
    width: 100%;
    border-collapse: unset;
    border-radius: 20px;

    .table-head {
      background-color: $getphy-light-background;
    }

    .table-head.border-table-deposit tr {
      th:first-child {
        border-top-left-radius: 20px;
      }
      th:last-child {
        border-top-right-radius: 20px;
      }
    }

    .table-head-title {
      border-top: solid 1px $color-gray;
      color: $getphy-main-text;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.6rem;
    }

    .table-head-title-date {
      padding-left: 4.3rem;
      width: 320px;
    }

    .table-head-title-name {
      border-top: 0;
      padding-left: 4.3rem;
      width: 300px;
    }

    .table-head-title-deposit {
      border-top: 0;
      padding-bottom: 12px;
      padding-top: 12px;
    }

    .table-body-unsubscribed{
      background-color: $color-gray;
    }

    .table-body-colored {
      background-color: $getphy-light-background;
    }

    .table-body-even:nth-child(even) {
      background-color: $getphy-light-background;
    }

    .table-cell-title-name {
      padding-left: 2rem;
    }

    .table-body-cell {
      padding: 0.6rem;
      font-size: 1rem;
      font-weight: 400;
    }

    .table-head-border-left {
      border-top-left-radius: 15px;
    }

    .table-head-border-right {
      border-top-right-radius: 15px;
    }

    .table-cell-width {
      width: 140px;
    }

    .table-cell-width-cheque {
      width: 125px;
    }

    .table-cell-width-actions {
      width: 100px;
    }

    .table-cell-width-card {
      width: 145px;
    }

    .table-cell-width-name {
      width: 300px;
    }

    .table-cell-width-action-deposit {
      width: 115px;
    }

    .cell-text-centered {
      text-align: center;
      vertical-align: middle;
    }

    .table-cell-actions-desktop{
      display: none;

      @include widescreen {
        display: flex;
        justify-content: space-evenly;
      }

      .navbar-item {
        padding: 7px;
      }
    }

    .table-cell-cheques-empty {
      text-align: center;
      vertical-align: middle;
    }

    .navbar-dropdown {
      min-width: 0;
      border-top: none;
      border-radius: 6px;
      box-shadow: $box-shadow;

      .icon-action {
        color: $color-gray-chalk;
        font-size: 0.875rem;
      }
    }

    .bank-deposit-line-corners tr:last-child .table-body-cell:first-child {
      border-bottom-left-radius: 20px;
    }

    .navbar-item-link {
      align-items: center;
      display: flex;
      color: $color-gray-chalk;
    }

    .navbar-button {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      white-space: nowrap;

      & > button {
        border: none;
        background-color: transparent;
        color: $color-gray-chalk;
        cursor: pointer;
        padding-left: 0;
      }

      button {
        cursor: not-allowed;
      }
    }

    .table-cell-actions-tablet {
      display: flex;
      justify-content: space-evenly;

      @include widescreen {
        display: none;
      }
    }

    .cell-icon-action {
      display: flex;
      justify-content: center;
    }

    .cell-text-name {
      overflow: hidden;
      padding-left: calc(2rem - 12px);
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;

    }

    .cell-text-date {
      padding-left: 3rem;
      vertical-align: middle;
    }

    .icon-transfer {
      max-width: 1rem;
      cursor: pointer;
    }

    .icon-disabled {
      opacity: 0.4;
      cursor: not-allowed;

      .icon-pencil {
        cursor: not-allowed;
      }
    }

    .ellipsis-disabled {
      opacity: 0.4;
    }

    .with-shortStatus {
      display: flex
    }

    .status-pending {
      border-left: 12px solid $color-payment-waiting;
      box-sizing: border-box;
    }
    .status-to-finalize {
      border-left: 12px solid $color-payment-finalise;
      box-sizing: border-box;
    }
    .status-accepted {
      border-left: 12px solid $color-payment-accepted;
      box-sizing: border-box;
    }
    .status-overpaid {
      border-left: 12px solid $color-payment-overpaid;
      box-sizing: border-box;
    }
    .status-refused {
      border-left: 12px solid $color-payment-refused;
      box-sizing: border-box;
    }
    .status-abandoned {
      border-left: 12px solid $color-payment-abandoned;
      box-sizing: border-box;
    }
    .status-is-unsubscribed, .status-not-applicable {
      border-left: 12px solid $color-payment-unsubscribed;
      box-sizing: border-box;
    }

    .payments-navbar-link {
      padding: 0.375rem 1rem;
      text-align: start;
    }
  }

  tbody tr:last-of-type td:first-child {
    border-bottom-left-radius: 20px;
  }

  tbody tr:last-of-type td:last-child {
    border-bottom-right-radius: 20px;
  }
}
