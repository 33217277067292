.custom-error-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .custom-error-message {
    align-items: center;
    background: $getphy-error-transparent;
    border-radius: 5px;
    color: $color-dark-gray;
    display: flex;
    font-size: 0.9rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding: 10px;
    padding-right: 2rem;

    .icon-search {
      float: left;
      height: 1.5em;
      margin: 10px 15px 10px 5px;
    }

    .icon-circle-x-mark {
      margin-right: 0.5rem;
      width: 1.25rem;
    }
  }
}