@import '@app/styles/variables';

.payment-status {
  color: $white;
  padding: 8px;
  text-align: center;

  &.not-registered {
    background-color: $getphy-accentuated;
  }
  &.to-pay {
    background-color: $getphy-error;
  }
  &.requested {
    background-color: $getphy-grey-text;
  }
  &.paid {
    background-color: $getphy-green-element;
  }
  &.free {
    background-color: $getphy-primary;
  }
}
