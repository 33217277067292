.search-field-map {
  width: 100%;

  .search-field-input-map {
    width: 100%;
    margin-right: 1rem;
    z-index: 11;

    @include mobile {
      margin-right: 0;
    }
  }

  .input {
    box-shadow: none;
  }
}
