@mixin loader-border {
  border: 3px solid transparent;
  border-radius: 50%;
}

.loader-component {
  margin: auto;
  position: relative;
  top: 50%;

  .light {
    @include loader-border;
    border-right-color: $color-white;
    border-top-color: $color-white;
  }

  .dark {
    @include loader-border;
    border-right-color: $getphy-primary;
    border-top-color: $getphy-secondary;
  }

  .loader-element {
    left: 50%;
    position: absolute;
    top: 50%;
  }

  .loader-outer {
    animation: spin 2s linear infinite;
    height: 3.5em;
    margin-left: -1.75em;
    margin-top: -1.75em;
    width: 3.5em;
  }

  .loader-middle {
    animation: spin 1.75s linear reverse infinite;
    height: 2.1em;
    margin-left: -1.05em;
    margin-top: -1.05em;
    width: 2.1em;
  }

  .loader-inner {
    animation: spin 1.5s linear infinite;
    height: 0.8em;
    margin-left: -0.4em;
    margin-top: -0.4em;
    width: 0.8em;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.loader-page {
  margin: auto;
}

.loader-background {
  width: 100%;
  background-color: $getphy-light-background;
}

.loader-title {
  text-align: center;
}

.loader-div {
  height: 5rem;
}
