@import '@app/styles/variables';
@import '@app/styles/mixins';

.beneficiary-payment-info {
  .title-condition {
    color: $getphy-label-text;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  .condition-checkbox {
    cursor: default;
    margin-top: 0.5rem;
    font-weight: 400;
  }

  .payment-condition {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    align-items: center;

    .field {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .columns {
      align-items: center;

      .column {
        padding: 0.25rem;
      }

      .column:first-child {
        padding-left: 0;
      }

      .column:last-child {
        padding-right: 0;
      }

      .column p {
        font-weight: 700;
        color: $getphy-label-text;
        margin-bottom: 0;
      }
    }
  }

  .commentary-container {
    margin-bottom: 3rem;
  }

  .total-amount {
    color: $getphy-label-text;
    font-weight: 700;
  }
}
