.group-class-card-container {
  padding: 2rem 0;
}

.group-class-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .faq-toggle-btn {
    a:hover {
      color: $getphy-secondary;
    }
    color: $getphy-secondary;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
}

.group-class-card {
  box-shadow: $box-shadow;
  border-radius: 10px;
  overflow: hidden;

  h2 {
    margin-bottom: 1rem;
    color: $getphy-main-text;
  }

  .header {
    background-color: $getphy-primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .group-class-card-content {
    padding-bottom: 2rem;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: $getphy-main-text;

    @include mobile {
      padding: 1rem;
      padding-bottom: 1.5rem;
    }

    .group-class-card-title {
      font-style: normal;
      font-weight: 500;

      @include mobile {
        font-size: 1.25rem;
      }
    }

    .group-class-card-title:not(:first-child) {
      margin-top: 0;
    }
  }

  .name,
  .coach {
    color: $color-white;

    @include mobile {
      &:first-child {
        padding-bottom: 0;
      }
    }
  }

  .coach {
    @include mobile {
      padding-bottom: 2rem;
    }
  }

  .icon-marker {
    fill: $getphy-accentuated;
    margin-right: 1rem;
    width: 2rem;
    min-width: 2rem;
  }

  .infos {
    max-width: 90%;
    font-weight: bold;
    color: $getphy-primary;
    margin-bottom: 1rem;
  }

  .infos-datetime,
  .infos-address {
    padding-bottom: 1rem;
    padding-top: 1rem;
    display: flex;
    align-items: center;
  }

  .group-class-card-content-block {
    margin-bottom: 2rem;
  }

  .block-preregister-prices {
    display: inline-flex;
    width: 100%;

    @include mobile {
      display: flex;
      flex-direction: column-reverse;
    }

    .price-block {
      width: 66%;

      @include mobile {
        width: 100%;
      }
    }

    .no-margin-top {
      margin-top: 0;

      @include mobile {
        margin-top: 1.5rem;
      }
    }

    .price {
      list-style: disc;
      margin-top: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .preregister-block {
    background-color: $getphy-light-background;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: $box-shadow;

    @include mobile {
      margin-bottom: 1rem;
    }

    .first-time-preregister-text {
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      text-align: center;

      @include tablet {
        margin-top: 1.5rem;
      }
    }

    .first-time-preregister-button {
      color: initial;
      margin-top: 1rem;
      margin-left: 6rem;
      margin-right: 6rem;

      @include tablet {
        margin-left: 4rem;
        margin-right: 4rem;
      }

      @include mobile {
        margin-left: 3rem;
        margin-right: 3rem;
      }
    }
  }

  .button-cancel-preregister {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button-div {
    @include mobile {
      margin: 0 auto;
    }
  }
}
