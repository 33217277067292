.notification-badge {
    background: $getphy-accentuated;
    border-radius: 100%;
    color: $getphy-main-text;
    font-weight: 700;
    height: 1.5rem;
    line-height: 1.5rem;
    position: absolute;
    right: -0.75rem;
    text-align: center;
    top: -0.5rem;
    width: 1.5rem;
}
