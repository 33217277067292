.login-container {
  padding: 2rem 0;
  .login-content {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    max-width: 70%;
    margin: 0 auto;
    @include mobile {
      max-width: inherit;
    }

    h1 {
      text-align: center;
      margin-bottom: 50px;
    }

    .forgot-password {
      text-align: center;
      margin: 1.5rem 0;
    }

    .forgot-password a {
      color: $getphy-secondary;
    }

    .forgot-password a:hover {
      color: $getphy-secondary;
    }

    .field {
      max-width: 500px;
      margin: 0 auto 1rem;
    }

    .field .icon.icon-eye {
      position: absolute;
      right: 12px;
      top: 10px;
      cursor: pointer;
    }

    .field.password-validated input {
      border: 2px solid $getphy-green-element;
    }

    .field.error-password-input input {
      border: 2px solid $getphy-error;
    }

    .button {
      display: flex;
      margin: 1em auto;
    }

    .error-password {
      color: $getphy-error;
    }

    .password-hint-container {
      max-width: 500px;
      margin: 1.5rem auto 0;
      background-color: $getphy-light-background;
      padding: 1.5rem 1rem;
      border-radius: 10px;
    }

    .password-hint-container-error {
      border: 2px solid $getphy-error;
    }

    .password-hint {
      position: relative;
      margin-bottom: 0.5rem;
    }

    .password-hint:last-child {
      margin-bottom: 0;
    }

    .password-hint .icon-tick {
      position: absolute;
      left: -0.4rem;
      top: -0.2rem;
      width: 2rem;
      fill: $color-gray;
    }

    .password-hint .icon-tick.green-tick {
      fill: $getphy-green-element;
    }

    .password-hint .password-hint-text {
      padding-left: 2rem;
    }

    .custom-error-message-valid-email {
      max-width: 500px;
      margin: 0 auto;
      background-color: $getphy-light-background;
    }

    .icon-infos {
      width: 3rem;
      margin-right: 0.5rem;
    }

    .link-go-back-container {
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .link-go-back-container .link-go-back {
      display: inline-block;
    }

    .tool-tip-container {
      position: relative;
      top: -2px;
    }

    .icon-question-mark {
      fill: $getphy-accentuated;
      margin-left: 1rem;
      vertical-align: middle;
      cursor: pointer;
    }

    .special-char-title {
      margin-bottom: 0.5rem;
    }

    .tooltip.tooltip-popin-top {
      left: 65%;
    }
  }
}
