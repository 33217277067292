.date-picker-component {
  display: flex;
  flex-direction: column;
  user-select: none;

  .date-picker-month {
    font-family: $family-sans-serif;
    font-size: 1.6rem;
    font-weight: $weight-light;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include desktop {
      font-family: $family-sans-serif;
      font-size: 2rem;
      font-weight: $weight-light;
    }
  }

  .date-picker-month-value {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 32px;
    text-transform: capitalize;
    cursor: default;

    &.with-month {
      cursor: pointer;

      &:hover {
        background-color: rgba($color-gray, 0.2);
      }
    }
  }

  .icon-arrow {
    display: flex;
    flex-shrink: 0;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    padding: 8px;
    cursor: pointer;
  }

  .arrow-previous {
    transform: rotate(180deg);
    margin-right: 16px;
  }

  .arrow-next {
    margin-left: 16px;
  }
}
