.custom-toast {
  &.notification {
    cursor: pointer;
    animation-duration: .8s;
    font-weight: $weight-bold;
    color: $color-white;
    border-radius: 8px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    padding: 1.25rem 1.75rem 1.25rem 1.25rem !important;

    @include desktop {
      left: 20px;
      bottom: 20px;
    }

    .icon-search {
      margin-right: 1rem;
      font-size: 1.5rem;
    }

    .icon-check-toast, .icon-warning-toast {
      fill: $color-white;
      margin-right: 0.5rem;
    }

    &.is-info {
      background-color: $getphy-primary;
    }

    &.is-success {
      background-color: $getphy-green-element;
    }

    &.is-warning {
      background-color: $getphy-accentuated;
    }

    &.is-danger {
      background-color: $getphy-error;
    }
  }
}
