.group-class-send-message {

  h1 {
    color: $getphy-main-text;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .return-button {
    margin-bottom: 2rem;
  }

  .group-class-send-container {
    background-color: $getphy-light-background;
    border-radius: 10px;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    padding: 1.3rem;

    @include touch {
      align-items: center;
    }

    h1 {
      margin-top: 0;
    }
  }

  .container-flex {
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    @include touch {
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 1.8rem;
    }
  }

  .container-white {
    background-color: $color-white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    padding: 2rem;
    width: 100%;
  }

  .send-input {
    width: 100%;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4rem auto auto;

    @include tablet {
      flex-direction: row;
      max-width: 56%
    }
  }

  .cancel-button {
    flex-grow: 1;
    margin-bottom: 1rem;

    @include tablet {
      margin-right: 1rem;
    }
  }

  .send-button {
    flex-grow: 1;
  }

  .group-send-recipients-placeholder {
    color: hsl(0, 0%, 80%);
  }

  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 38px;
    margin-bottom: 0;
  }

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 38px;
    margin: 0;
    opacity: 0;
  }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 38px;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: $getphy-grey-text;
    background-color: $color-white;
    border: 1px solid $color-gray;
    border-radius: 0.25rem;
    overflow: hidden;

    &.red {
      border: 1px solid $getphy-error;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      padding: .375rem .75rem;
      font-weight: 700;
      line-height: 1.5;
      color: $color-white;
      content: attr(data-content);
      background-color: $secondary;
      border-left: inherit;
      border-radius: 0 .25rem .25rem 0;
    }
  }
}
