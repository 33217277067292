.group-class-detailed {
  background-color: $getphy-light-background;
  border-radius: 10px;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1.3rem;

  @include touch {
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  @include mobile {
    width: 90%;
  }

  .button-container {
    display: flex;

    @include touch {
      flex-direction: column;
    }

    .button-secondary {
      @include touch {
        margin-bottom: 1rem;
        width: 100%;
      }

      @include desktop {
        margin-left: 1rem;
      }
    }
  }

  .title-one {
    color: $getphy-main-text;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .group-class-container-white {
    background-color: $color-white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    padding: 30px;
    width: 100%;
  }

  .group-class-block-title {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    .group-class-title {
      @include touch {
        margin-bottom: 1rem;
      }
    }
    .group-class-detailed-title {
      color: $getphy-secondary;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.45rem;
      margin-bottom: 0;
      span {
        font-size: 1rem;
        display: inline-block;
        margin-left: 0.5rem;
      }
    }

    @include touch {
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    &.group-class-block-title-detailed {
      padding-bottom: 1.3rem;
    }
  }

  .edit-button {
    margin-bottom: 0;

    @include touch {
      margin-bottom: 1rem;
    }
  }

  .wrapper {
    cursor: not-allowed;
    display: flex;
    .icon-disabled {
      fill: $getphy-grey-text;
      opacity: 0.5;
      pointer-events: none;
      &.button {
        background-color: $getphy-grey-text;
      }

      &.button svg {
        fill: $color-white;
      }
    }
  }
}
