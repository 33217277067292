@import '@app/styles/variables';

.bubble {
  --bubble-size: 3rem;

  background: $getphy-main-text;
  border-radius: 50%;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-family: sans-serif;
  font-weight: normal;
  height: var(--bubble-size);
  line-height: var(--bubble-size);
  margin-block-end: 0;
  margin-block-start: 0;
  max-height: max-content !important;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: var(--bubble-size);
}

  .navbar {
    padding: 0.735rem;
    position: relative;
    border-bottom: 2px solid $color-gray-light;
    display: flex;
    align-items: center;
    font-weight: 400;

    .navbar-brand-logo {
      height: 4.25rem;
      @include tablet {
        height: 4.6rem;
      }
    }

    .navbar-end {
      text-align: center;
      * {
        @include desktop {
          margin-left: 0.5rem;
        }
      }
    }

    .navbar-link {
      display: none;
      h3 {
        display: block;
        font-size: 1rem;
        color: $getphy-grey-text-secondary;
        font-weight: 400;
      }
      @include desktop {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        padding: 0;

        .icon-avatar {
          width: 2rem;
          margin-right: 0;
        }

        .icon-chevron {
          fill: $getphy-grey-text;
          transform: rotate(-90deg);
          width: 0.7rem;
          margin: 0 1rem;
       }
        &:hover {
          text-decoration: none;
          background-color: transparent;
          color: inherit;
        }
      }
    }

    a.navbar-item.is-active {
      color: $getphy-primary;
      font-weight: 600;
    }

    .navbar-item {
      font-size: 1rem;
      color: $getphy-grey-text-secondary;
      cursor: pointer;
      &:hover {
        background-color: transparent;
        text-decoration: underline;
        color: $getphy-grey-text-secondary;
      }
      &:focus {
        background-color: transparent;
      }

      .tab-element-badge{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        font-size: 10px;
        border-radius: 18px;
        background-color: $getphy-accentuated;
        color: $getphy-main-text;
        font-weight: $weight-medium;
        transform: translateY(-.5rem);
        margin-left: 2px;
      }
    }

    .as-button {
      &:hover {
        text-decoration: none;
      }
    }

    .navbar-dropdown {
      box-shadow: none;
      @include desktop {
        border: 1px solid $getphy-light-background;
        border-radius: 5px;
      }
      hr {
        margin: 0.5rem 0;
        @include desktop {
          display: none;
        }
      }

      .navbar-item {
        width: 10rem;
        padding-left: 1rem
      }
      .is-active {
        color: $getphy-primary !important;
      }
      &:hover {
        a {
          text-decoration: none;
        }
      }
    }

    .navbar-dropdown:hover a:hover {
      text-decoration: underline;
      background-color: transparent;
      color: $getphy-grey-text-secondary;
    }

    .navbar-item.has-dropdown:hover {
      background-color: transparent;
      text-decoration: none;
      color: inherit;
      .navbar-link {
        background-color: transparent !important;
        text-decoration: none;
      }
    }

    .navbar-burger {
      display: flex;
      align-items: center;
      height: 100%;
      h3 {
        margin-top: 0.4rem;
        margin-left: 0.5rem;
        margin-right: 0.8rem;
        font-weight: bold;
      }
      &:hover {
        background-color: transparent;
      }
      @include desktop {
        display: none;
      }
    }

    .navbar-menu.is-active {
      position: absolute;
      padding: 0.5rem;
      text-align: left;
      border-radius: 5px;
      top: 5.375rem;
      right: 0;

      .tab-element-badge {
        margin-left: 163px;
        margin-top: -30px;
      }
    }
  }
