.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.w-100 {
  min-width: 100%;
}

.inline-block {
  display: inline-block;
}

.uppercase-first {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.color-error {
  fill: $getphy-error;
}

.color-success {
  fill: $getphy-green-element;
}

.helper {
  display: flex;
  justify-content: space-between;
}

.help-char-left {
  flex-direction: row-reverse;
}

.separator {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 4rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-black, 0.95);
  z-index: 100;

  &.with-blur {
    filter: blur(4px);
  }

  &.overlay-edge {
    opacity: 0;
    pointer-events: none;
    z-index: 0;

    @include touch {
      opacity: 1;
      pointer-events: inherit;
      z-index: 100;
    }
  }
}

/* CONTAINER BULMA MOBILE */
.container {
  width: 100%;
  .columns {
    width: 100%;
    margin: 0;
  }
}
@include touch {
  .container {
    max-width: 90%;
  }
}
