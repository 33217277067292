.trial-banner {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }

  @include tablet {
    flex-direction: row;
  }

  .trial-banner-left-block {
    background-color: $getphy-primary;
    color: $color-white;
    width: 25rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    @include mobile {
      width: 100%;
    }

    .trial-banner-title {
      color: $color-white;
      margin-bottom: 1rem;
    }

    p {
      display: flex;
      flex-direction: column;
    }

    .trial-banner-date-coach {
      margin-top: 1rem;
      @include mobile {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }

    .trial-banner-coach {
      margin-top: 1rem;
      flex-direction: row;
      @include mobile {
        margin-top: 0;
      }
    }

    img {
      height: 100%;
      position: absolute;
      left: calc(100% - 1.5rem);
      @include touch {
        display: none;
      }
    }
  }

  .trial-banner-arrow-right {
    margin-left: -4px;
    @include mobile {
      display: none;
    }
  }

  .trial-banner-right-block {
    background-color: $getphy-light-background;
    padding: 2rem 1rem 2rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @include mobile {
      padding: 1rem;
    }

    .trial-banner-title-mentions {
      color: $getphy-secondary;
      margin-bottom: 1rem;
    }

    .trial-banner-cancel-block {
      @include tablet {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }

    .trial-banner-mention-two {
      margin-top: 1rem;
    }

    .trial-banner-see-more {
      display: flex;
      justify-content: center;
      margin: 1.5rem;

      @include tablet {
        margin-bottom: 0;
      }

      @include mobile {
        justify-content: flex-start;
        margin-left: 0;
      }
    }

    .trial-banner-cancel-btn {
      color: $getphy-secondary;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;

      &:hover {
        color: $getphy-main-text;
      }

      @include mobile {
        justify-content: flex-start;
      }
    }
  }

  &:nth-last-child(2) {
    margin-bottom: 0;
  }
}
