.tooltip {
  position: absolute;
  left: 0;
  padding: 1rem;
  border-radius: 10px;
  background-color: $white;
  z-index: 1;
  width: 230px;
  box-shadow: $box-shadow;

  &.tooltip-popin-top {
    bottom: calc(100% + 13px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.tooltip-popin-bottom {
    top: calc(100% + 13px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.tooltip-popin-left {
    top: 0;
    left: -8px;
    transform: translateX(-100%) translateY(-25%);
  }

  &.tooltip-popin-right {
    top: 0;
    left: -6px;
    transform: translateX(50%) translateY(-25%);
  }

  .tooltip-text {
    font-size: 0.9rem;
    color: $getphy-main-text;
  }

  .tooltip-arrow {
    position: absolute;
    width: unset;
    height: unset;
    border: 0;

    &::before {
      display: block;
      content: '';
    }

    &.tooltip-arrow-up,
    &.tooltip-arrow-down {
      left: 50%;
      transform: translateX(-50%);
    }

    &.tooltip-arrow-up {
      bottom: 100%;

      &::before {
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
        border-left: 10px solid transparent;
      }
    }

    &.tooltip-arrow-down {
      top: 100%;

      &::before {
        border-top: 10px solid $white;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
      }
    }

    &.tooltip-arrow-left {
      top: 25%;
      right: calc(100% + 12px);
      left: unset;

      &::before {
        top: -10px;
        right: -11px;
        border-top: 10px solid transparent;
        border-right: 10px solid $black;
        border-bottom: 10px solid transparent;
      }
    }

    &.tooltip-arrow-right {
      left: 100%;
      top: 25%;

      &::before {
        top: -10px;
        left: -11px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $black;
      }
    }
  }
}
