@import '@app/styles/variables';
@import '@app/styles/mixins';

.beneficiary-payment-edit {
  .title-condition {
    color: $getphy-label-text;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  .condition-checkbox {
    cursor: default;
    margin-top: 0.5rem;
    font-weight: 400;
  }

  .payment-condition {
    display: flex;
    flex-wrap: wrap;

    .columns {
      align-items: center;

      .column {
        padding: 0.25rem;
      }
      .column:first-child {
        padding-left: 0;
      }

      .column:last-child {
        padding-right: 0;
      }

      .column p {
        font-weight: 700;
        color: $getphy-label-text;
        margin-bottom: 0;
      }
    }
  }

  .total-amount {
    color: $getphy-label-text;
    font-weight: 700;
  }

  .comment {
    border: 2px solid $getphy-primary;
    border-radius: 10px;
    margin-top: 2rem;
    position: relative;

    .textarea {
      min-height: 5em;
      z-index: 1;
      margin-top: 0.75rem;
      border: none;
      box-shadow: none;
      padding-top: 0;
    }

    .label {
      background: $color-white;
      left: -0.5rem;
      margin-bottom: 0;
      padding: 0 0.75rem;
      position: absolute;
      top: -0.75rem;
      z-index: 4;
    }
  }

  .frequency {
    display: flex;
    flex-direction: column;

    @include desktop {
      align-items: center;
      flex-direction: row;
      justify-content: start;
    }
  }

  .frequency-checkbox-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    width: 100%;

    @include desktop {
      margin-top: 0;
    }

    @include tablet {
      justify-content: start;
    }

    .frequency-checkbox {
      @include tablet {
        padding-right: 2rem;
      }
    }
  }

  .period-value {
    width: 100%;

    @include tablet {
      width: 40%;
    }

    @include desktop {
      width: 30%;
    }
  }
}
