.group-class-admin-header {
  background: $getphy-light-background;
  .group-class-admin-header-name {
    color: $getphy-secondary;
    font-weight: bold;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
  }

  .buttons {
    display: none;

    @include desktop {
      display: flex;
      justify-content: space-evenly;
      margin-top: 1rem;
    }
  }

  .group-class-admin-header-navigation {
    display: none;
    width: 100%;
    z-index:1;

    @include desktop {
      display: block;
    }
  }

  .header {
    align-items: center;
    background: $getphy-light-background;
    display: flex;
    justify-content: center;
    padding: 2rem 1rem 1rem;
    width: 100%;
    z-index: 2;

    @include desktop {
      margin-bottom: 0;
    }

    .header-title {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .group-class-admin-header-desktop {
      text-align: center;
    }

    .group-class-admin-header-address {
      @include mobile {
        width: 80%;
        margin: 0 auto;
      }
    }

    .icon-burger-menu {
      stroke: $getphy-secondary;
      margin-right: 1rem;
      cursor: pointer;
      position: absolute;
      left: 0;
      margin-left: 1.5rem;
      &:hover {
        stroke: $getphy-secondary;
        stroke-opacity: 0.9;

      }
      @include desktop {
        display: none;
      }
    }
  }
}
