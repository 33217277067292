@import '@app/styles/variables';
@import '@app/styles/mixins';

.image-component{

  .hide-image{
    display: none;
  }

  .image-loader-container {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    background: rgba($color-black, .02);

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .circular {
      animation: rotate 2s linear infinite;
      height: 44px;
      transform-origin: center center;
      width: 44px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .no-image-picture{
      margin: auto;
      width: 50%;
      max-width: 200px;
    }

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  }

  .image-loader-parent{
    width: 100%;
    height: 100%;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $getphy-primary;
  }
  40% {
    stroke: $getphy-secondary;
  }
  66% {
    stroke: $getphy-secondary;
  }
  80%,
  90% {
    stroke: $getphy-accentuated;
  }
}
