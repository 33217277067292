@import '@app/styles/variables';

.footer {
  background-color: #0B3856;
  margin-top: 4rem;
}

.footer-container {
  max-width: 90rem;
  margin: 0 auto;

  .footer-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid white;
    margin-top: 2rem;

    @include desktop {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 0 2rem;
      margin-top: 1rem;
    }
  }

  .social {
    max-width: 27rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.4rem 0 2.5rem 0;
    transition: fill 0.3s ease;
    flex-direction: column;

    @include desktop {
      margin: 0;
      padding-left: 2rem;
      flex-direction: row;
    }

    .follow {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      @include desktop {
        margin: 0;
      }
      h3 {
        color: white;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 18px;
        margin-right: 0.5rem;
      }
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 16rem;
    }

    .icon-social {
      cursor: pointer;
      height: 35px;
      margin: 0 0.5rem 0 0.5rem;
    }
  }

  .icon-facebook {
    .bg {
      fill: white;
    }
    .icon {
      fill: black;
    }
  }

  .icon-x {
    .bg {
      fill: white;
    }
    .icon {
      fill: black;
    }
  }

  .icon-linkedin {
    .bg {
      fill: white;
    }
    .icon {
      fill: black;
    }

  }

  .icon-youtube {
    .bg {
      fill: white;
    }
    .icon {
      fill: black;
    }
  }

  .icon-instagram {
    .bg {
      fill: white;
    }
    .icon {
      fill: black;
    }
  }

  .icon-linkedin :hover {
    .bg {
      fill: #0077B5;
    }
    .icon {
      fill: white;
    }
  }

  .icon-youtube :hover {
    .bg {
      fill: #FF0000;
    }
    .icon {
      fill: white;
    }
  }

  .icon-instagram :hover {
    .bg {
      fill: #E1306C;
    }
    .icon {
      fill: white;
    }
  }

  .icon-x :hover {
    .bg {
      fill: black;
    }
    .icon {
      fill: white;
    }
  }

  .icon-facebook :hover {
    .bg {
      fill: #3b5998;
    }
    .icon {
      fill: white;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0 1rem 0;

    @include desktop {
      flex-direction: row;
      justify-content: space-around;
    }

    a {
      font-size: 17px;
      color: white;
      margin-bottom: 1rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .delete {
    float: right;
    background: transparent;

    &:before {
      background-color: black;
    }

    &:after {
      background-color: black;
    }
  }
}
