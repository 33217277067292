@import '@app/styles/variables';

.modal-footer-yes-no {
  display: flex;
  padding-bottom: 1rem;
  justify-content: flex-end;

  .button-modal {
    margin-right: 2rem;
    min-width: 130px;
    text-transform: capitalize;
  }
}
