.group-class-send {
  .group-class-send-content {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include tablet {
      align-items: start;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}