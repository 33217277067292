@use 'sass:color';

.button {
  font-size: 1rem;
  font-style: normal;
  font-weight: $weight-bold;

  &.button-primary {
    background-color: $getphy-accentuated;
    border-color: transparent;
    color: $getphy-main-text;

    &.is-loading:after{
      border-left-color: $getphy-main-text;
      border-bottom-color: $getphy-main-text;
      top: unset !important;
      left: unset !important;
    }

    &:hover {
      background-color: color.adjust($getphy-accentuated, $lightness: -5%);
    }
  }

  &.button-secondary {
    background-color: $getphy-secondary;
    border-color: transparent;
    color: $color-white;

    &.is-loading:after{
      border-left-color: $color-white;
      border-bottom-color: $color-white;
    }

    &:hover {
      background-color: color.adjust($getphy-secondary, $lightness: -5%);
      color: $color-white;
    }
  }

  &.button-tertiary {
    background-color: transparent;
    border-color: $getphy-secondary;
    color: $getphy-secondary;

    &.is-loading:after {
      border-left-color: $getphy-secondary;
      border-bottom-color: $getphy-secondary;
    }

    &:hover {
      background-color: $getphy-secondary;
      color: $color-white;
    }
  }

  &.button-quarternary {
    color: $getphy-primary;
    font-size: 20px;
    text-transform: uppercase;
    border: 1px solid $getphy-primary;
    border-radius: 5px;

    &:hover {
      background-color: $getphy-blue-light;
      text-decoration: none !important;
    }
  }

  &.button-quinary {
    color: $color-white;
    font-size: 20px;
    text-transform: uppercase;
    border: 1px solid $getphy-primary;
    border-radius: 5px;
    background-color: $getphy-primary;

    &:hover {
      background-color: $getphy-secondary;
      text-decoration: none !important;
    }
  }

  &.button-danger {
    background-color: $getphy-error;
    border-color: transparent;
    color: $color-white;

    &.is-loading:after {
      border-left-color: $color-white;
      border-bottom-color: $color-white;
    }

    &:hover {
      background-color: color.adjust($getphy-error, $lightness: -5%);
    }
  }

  &.is-disabled {
    background-color: $color-white;
    border-color: $color-gray;
    box-shadow: none;
    color: $color-dark-gray;
    opacity: 0.5;
    cursor: not-allowed;

    &.is-loading:after {
      border-left-color: $color-dark-gray;
      border-bottom-color: $color-dark-gray;
    }

    &:hover {
      background-color: $color-white;
      color: $color-dark-gray;
    }
  }

  .back-icon {
    margin-right: 0.75rem !important;
    max-width: 0.7rem;
    fill: $getphy-secondary;
    margin-left: 0.1rem !important;
  }

  &:hover {
    cursor: pointer;

    .back-icon {
      fill: $color-white;
    }
  }
}
