.modal-export-card {
  overflow: visible;

  .modal-export-text {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .modal-export-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
}
