.shared-sessions-container {
  padding: 2rem 0;

  .header {
    margin-bottom: 2rem;
    padding: 0 0.75rem;

    .no-result {
      color: $getphy-grey-text;
      font-weight: 700;
      margin-top: 2rem;
      display: inline-block;
      font-size: 19px;
    }
  }

  .column-no-result {
    padding-top: 0;
    .no-result {
      color: $getphy-grey-text;
      font-weight: 700;
      margin-top: 2rem;
      display: inline-block;
      font-size: 19px;
    }
  }

  .shared-sessions-registered {
    width: 100%;
    margin-bottom: 2.5rem;
    @include mobile {
      margin-bottom: 1.5rem;
    }

    .shared-sessions-registered-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
    }

    .shared-sessions-registered-title {
      padding: 0 0.75rem;
      margin-bottom: 1.5rem;
      @include mobile {
        margin-bottom: 1rem;
      }

      .group-classes-title {
        margin-bottom: 0.5rem;
      }
    }
  }

  .shared-sessions-card-item {
    background-color: $getphy-light-background;
    border-radius: 10px;
    box-shadow: $box-shadow;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: calc(400px - 4vh);
    height: fit-content;

    .shared-sessions-card-image {
      padding-top: 60px;
    }

    .text-column {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 20px;
    }

    .shared-sessions-card-item-name {
      font-family: $family-sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }

    .shared-sessions-card-item-button {
      padding-top: 40px;
      text-align: center;
    }
  }
}
