.search-banner {
  background-color: $getphy-light-background;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-around;
  margin: 0 auto 1rem;
  padding: 1rem 2rem;

  @include touch {
    justify-content: space-between;
  }

  @include mobile {
    max-width: inherit;
    flex-direction: column;
    align-items: center;

    .search-banner-checkbox:first-child {
      margin-bottom: 0.5rem;
    }
  }

  &.search-banner-checkbox {
    margin-bottom: 0;
  }

  .search-banner-checkbox-checkbox {
    @include mobile {
      margin-bottom: 1rem;
    }

    input {
      transform: scale(1.5);
    }
  }
}

.search-banner-search {
  display: flex;
  justify-content: center;

  @include touch {
    margin-bottom: 0.5rem;
    margin-right: 0;

    button.button {
      margin: 0.75rem 0;
    }

    .toggle-button {
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
