.group-classes-registered-loader {
  height: 5rem;
  display: flex;
  align-items: center;
}

.group-classes-registered {
  width: 100%;
  margin-bottom: 2.5rem;
  @include mobile {
    margin-bottom: 1.5rem;
  }

  .group-classes-registered-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .group-classes-registered-pay {
    color: $getphy-main-text;
    margin-right: 30px;
    vertical-align: bottom;
  }

  .group-classes-title-heretopay {
    padding: 0 0.75rem;
    margin-bottom: 1.5rem;
    @include mobile {
      margin-bottom: 1rem;
    }

    .group-classes-title {
      margin-bottom: 0.5rem;
    }
  }

  @include touch {
    .group-classes-registered-button span {
      margin-bottom: 0.5rem;
      display: inline-block;
    }
  }
}
