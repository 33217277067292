.phone-field {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }

  .phone-field-input {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .phone-field-input-row {
    display: flex;
    flex-direction: row;
  }

  .list-requests-phone-field-country {
    white-space: nowrap;
    width: auto;
  }

  .phone-field-country-select {
    margin-bottom: 1rem;
    margin-right: 0.75rem;
    white-space: nowrap;
    width: auto;
  }

  .advanced-select-field-option {
    display: flex;
    align-items: center;

    .option-icon {
      display: flex;
      align-items: center;
      padding-right: 0.7rem;
    }
  }
}
