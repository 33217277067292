@import '@app/styles/mixins';

.modal-cheque-footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .button-modal {
    margin-bottom: 1rem;
    width: 50%;
  }
}

.modal-cheque-container {
  display: flex;
  flex-wrap: wrap;

  @include widescreen {
    flex-direction: column;
  }

  .field-modal {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    white-space: nowrap;
    width: 50%;

    @include widescreen {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  .registereds-loading {
    height: 40px;
    margin-top: 42px;
  }

  .overpayment {
    background: $color-payment-finalise;
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: $color-dark-gray;
    font-size: 0.9rem;
    padding: 10px;
    margin-top: 1rem;

    .icon-search {
      float: left;
      height: 1.5em;
      margin: 10px 15px 10px 5px;
    }
  }

  .existingCheque {
    background: $getphy-error-transparent;
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: $color-dark-gray;
    font-size: 0.9rem;
    padding: 10px;
    margin-top: 1rem;

    .icon-search {
      float: left;
      height: 1.5em;
      margin: 10px 15px 10px 5px;
    }
  }
}
