.beneficiary-request-card {
  .symbol {
    height: 1.75rem;
    width: 3rem;
  }

  .icon-minus, .icon-plus {
    fill: $getphy-primary;
    cursor: pointer;
    width: 3rem;
  }

  .header {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .title {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
  }

  .label-title {
    color: $getphy-primary;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .button-edit {
    width: 100%;

    @include tablet {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .error-registered {
    align-items: center;
    background-color: $getphy-error-transparent;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    max-width: 30%;
    margin: 0 auto;
    min-height: 2rem;
    margin-top: 2rem;

    .contact-error-icon {
      font-size: 1.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .error-contact-text {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }

  .buttons-footer {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @include touch {
      flex-direction: column;
    }
  }

  .card-form-title {
    align-items: center;
    color: $getphy-label-text;
    display: flex;

    @include touch {
      margin-bottom: 1rem;
    }
  }

  .registered-to {
    margin-top: -3rem;
    padding-left: 0;
    padding-top: 1rem;

    li {
      color: $getphy-main-text;
      list-style: inside;
    }
  }

  .block-infos {
    background-color: $color-white;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    .container {
      max-width: 100%;
    }

    .title {
      margin-bottom: 0;
    }

    .title-two {
      color: $getphy-secondary;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.45rem;
      margin-right: 0.5rem;

      @include desktop {
        font-size: 1.75rem;
      }
    }

    .flex-container {
      display: flex;

      @include touch {
        flex-direction: column-reverse;
      }
    }
    .period-container {
      flex: 1;
      width: 100%;

      table tr {
        vertical-align: middle;
      }

      tr.table-entitled {
        font-weight: 700;
        color: $getphy-label-text;
      }

      td {
        color: $getphy-label-text;
        border: none;
        vertical-align: middle;
        min-width: 7rem;
      }

      td:first-child {
        padding-left: 0;
      }

      td:last-child {
        padding-left: 0;
      }

      td .amountEuro {
        display: inline-block;
        width: 3rem;
      }

      td .tool-tip-container {
        position: relative;
      }

      .icon-question-mark {
        fill: $getphy-accentuated;
        margin-left: 1rem;
        vertical-align: middle;
        cursor: pointer;
      }

      .tool-tip-container ul {
        margin: 0;
      }

      .tool-tip-container li {
        list-style: none;
        margin-bottom: 0.25rem;
      }

      .tool-tip-container li:last-child {
        margin-bottom: 0;
      }

      .is-period-validated td, .is-period-validated .icon-question-mark {
        color: $getphy-grey-text;
      }

      .custom-error-container .errorPeriods {
        background-color: $getphy-light-background;
      }

      .errorPeriods .icon-infos {
        width: 1.25rem;
        margin-right: 0.5rem;
      }
    }

    .commentary-container {
      flex: 1;
      margin-top: 1rem;
    }

    .checkbox-label {
      color: $getphy-label-text;
      font-size: 1rem;
      font-style: normal;
    }
  }

  .block-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.75rem;
    width: 100%;
  }

  .title-button {
    display: flex;
    align-items: center;

    .button-edit {
      margin-right: 2rem;
    }
  }

  .content {
    padding: 0 1rem;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;

    &.is-open {
      transform: scaleY(1);
    }

    &.not-open {
      max-height: 0;
      overflow: hidden;
      padding: 0;
    }
  }

  .first-element {
    margin-top: 0;
  }

  .address {
    width: 100%;

    @include touch {
      display: block;
    }

    .last-element {
      margin-bottom: 0;
      @include touch {
        margin-bottom: 1.5rem;
      }
    }
  }

  .separator {
    border-bottom: 1px solid $getphy-inactive-element;
    width: calc(100% - 3rem);
    margin: 0 auto 1.5rem;

    &.not-displaying {
      display: none;
    }
  }

  .button-unsubscribe {
    @include tablet {
      margin: 0 auto;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  .block-group-classes-subscribed {
    margin-top: 2rem;
  }
}

/*
 * TODO: Revoir la gestion de ces styles, car ils sont global à l'application et peut implacter
 * l'ensemble des écrans
 */

.button-edit-mode {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;

  @include desktop {
    margin: 0 auto;
  }

  @include tablet {
    flex-direction: row;
  }

  .button-cancel {
    @include desktop {
      margin-right: 2rem;
    }

    @include mobile {
      margin-top: 1rem;
    }
  }

  .button-position {
    position: initial;
  }
}

.loader-personal-show {
  height: 4rem;
}
