.period-table {
  color: $getphy-label-text;
  display: flex;
  justify-content: space-around;

  @include mobile {
    flex-direction: column;
  }

  .value {
    font-weight: 400;
    @include mobile {
      margin-top: 1em;
    }
  }
  .period-value {
    display: flex;
    flex: 1.5;
    justify-content: space-around;
    @include mobile {
      justify-content: space-between;
    }
    .period-value-value {
      color: $getphy-main-text;
    }
  }
  .period-price {
    display: flex;
    flex: 1;
    justify-content: flex-start;

    .period-price-value {
      color: $getphy-main-text;
      padding-left: 1em;
    }
  }

  .period-table-title {
    font-weight: 700;
  }
}
