@import '@app/styles/variables';
@import '@app/styles/mixins';

.beneficiary-personal-edit {
  .block-flex {
    @include desktop {
      display: flex;
    }
  }

  .field {
    @include desktop {
      padding-right: 5rem;
    }

    @include tablet {
      width: 100%;
    }
  }

  .block-flex-country {
    align-items: flex-end;
    margin-bottom: 1rem;

    .input-personal-form {
      .field {
        padding-right: 0;
      }

      &.country {
        margin-bottom: 0;
      }
    }

  }

  .title-two {
    color: $getphy-primary;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .text-and-or {
    text-align: center;

    @include tablet-only {
      margin-bottom: 1rem;
      margin-top: 1rem;
      text-align: unset;
    }

    @include desktop {
      left: 41.5%;
      position: absolute;
    }

    @include widescreen {
      left: 45%;
      position: absolute;
    }

    @include fullhd {
      left: 46.5%;
      position: absolute;
    }
  }

  .phone-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .control {
    flex-grow: 1;
  }

  .contact {
    align-items: center;
  }

  .country-phone {
    margin-bottom: 0.5rem;
    margin-left: 0;
    margin-right: 0;
    width: auto;

    @include tablet {
      margin-bottom: 0;
      padding-right: 0.75rem;
    }

    @include desktop {
      padding-left: 0;
    }

    @include desktop-only {
      margin-bottom: 0.5rem;
      padding-right: 0;
      width: unset;
    }
  }

  .phone-input {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    @include desktop-only {
      flex-direction: column;
    }
  }

  .city-field {
    @include desktop {
      margin-bottom: 0.75rem;
    }
  }

  .half-width {
    @include desktop {
      width: 50%;
    }
  }

  .date-picker {
    margin-top: 1rem;

    @include desktop {
      width: 50%;
    }
  }

  .bloc-phone {
    flex-direction: column;
  }

  .text {
    color: $getphy-label-text;
    font-size: 0.875rem;
    font-weight: 700;
  }

  .error-contact {
    align-items: center;
    background-color: $getphy-error-transparent;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    max-width: 60%;
    margin: 0 auto 2rem;
    min-height: 2rem;
    padding-left: 1rem;

    .contact-error-icon {
      font-size: 1.5rem;
      margin-right: 1rem;
    }

    .error-contact-text {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }
}
