.time-picker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  user-select: none;

  .time-picker-hours {
    display: flex;
    flex-direction: column;
  }

  .time-picker-minutes {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }

  .time-picker-value {
    font-family: $family-sans-serif;
    font-size: 1.4rem;
    font-weight: $weight-light;
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: 26px;

    @include desktop {
      font-family: $family-sans-serif;
      font-size: 1.6rem;
      font-weight: $weight-light;
    }
  }

  .icon-arrow {
    display: flex;
    flex-shrink: 0;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    padding: 8px;
    cursor: pointer;
  }

  .chevron-down {
    transform: rotate(90deg);
  }

  .chevron-up {
    transform: rotate(270deg);
  }
}
