@charset "utf-8";
@import '@app/styles/bulma-variables';
@import '~@nodeModules/bulma/sass/utilities/mixins';

/*
  * Scroll Bar
  * -------------------------------------------------------------------------------------------------
  */
@mixin scroll-bar {
  scrollbar-color: $getphy-secondary;
  scrollbar-width: 8px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: inset 0 0 8px rgba($color-black, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($getphy-secondary, 0.8);
    box-shadow: inset 0 0 8px rgba($color-black, 0.5);
  }
}

@mixin scroll-bar-thin {
  scrollbar-color: $getphy-secondary;
  scrollbar-width: 4px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 15px;
    box-shadow: inset 0 0 8px rgba($color-black, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: rgba($getphy-secondary, 0.8);
    box-shadow: inset 0 0 8px rgba($color-black, 0.5);
  }
}
