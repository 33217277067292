@import '@app/styles/variables';

.period-selector {
  background-color: $getphy-light-background;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;

  .period-text {
    display: flex;
    justify-content: center;
    width: 430px;
  }

  .period-data {
    font-weight: 700;
    padding-left: 0.2rem;
  }

  .icon-circle-arrow {
    fill: $getphy-accentuated;
    border-radius: 50%;
    background-color: $color-white;
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;
  }
}
