@import '@app/styles/variables';

.payment-container {
  &.payments-global {
    .table-payment-container {
      overflow: hidden;
    }
  }

  .payment-container-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .button-validate {
    display:flex;
    justify-content: center;
    width: 100%;
  }
}
