.invoice-details {
  padding-top: 2rem;
  .invoice-details-header {
    display: flex;
    flex-direction: column;
  }

  .invoice-details-title {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
  }

  .invoice-details-field-container {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    @include mobile {
      flex-direction: column;
    }
  }

  .invoice-details-field {
    margin-bottom: 0.75rem;
    min-width: 45%;
  }

  .invoice-details-button {
    display: block;
    margin: 2rem auto;
  }
}
