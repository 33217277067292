.group-class-card-item {
  background-color: $getphy-light-background;
  border-radius: 10px;
  box-shadow: $box-shadow;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(400px - 4vh);
  height: fit-content;
  &.group-class-card-seasonClosed {
    position: relative;
    overflow: hidden;
    &:after{
      content: "";
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-gray, 0.4);
    }
  }

  .text-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
  }

  .group-class-card-item-category {
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    background-color: $color-payment-overpaid;
    color: $getphy-main-text;
    width: fit-content;
    .icon {
      fill: $getphy-main-text;
    }
  }

  .group-class-card-item-name {
    font-family: $family-sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .group-class-card-item-address {
    color: $getphy-main-text;
    font-family: $family-sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 2vh;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hide {
    opacity: 0;
  }

  .group-class-card-item-date {
    color: $getphy-primary;
    font-family: $family-sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    padding-bottom: 1vh;
  }

  .group-class-card-item-coach {
    color: $getphy-main-text;
    font-family: $family-sans-serif;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.2rem;
    .group-class-card-item-coach-name {
      text-decoration: underline;
    }
  }

  .group-class-card-item-infos {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;

    @include touch {
      display: block;
    }
  }

  .see-more {
    @include touch {
      margin-top: 1vh;
      width: 100%;
    }
  }

  .thumbnail {
    border-radius: 10px 10px 0 0;
    height: 200px;
    object-fit: cover;
    width: 100%;
  }
}
