.title-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2vh auto 0;

  @include touch {
    margin-top: 2vh;
    width: 100%;
  }

  @include mobile {
    align-items: center;
    margin-top: 2vh;
  }

  .title-banner-title {
    display: flex;
    flex-grow: 2;
    justify-content: center;
    margin-bottom: 1.5rem;

    @include mobile {
      margin-bottom: 1rem;
    }
  }

  .title-banner-toggle {
    display: flex;
    margin-bottom: 2rem;
    margin-top: 1rem;

    @include tablet {
      margin-right: 2vh;
    }

    @include mobile {
      margin-left: auto;
      margin-right: 1rem;
    }
  }

  .toggle-admin {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    @include touch {
      flex-direction: column;
      align-items: center;
    }
  }

  .title-banner-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    @include touch {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    @include mobile {
      flex-direction: column;
    }
  }

  .grow {
    flex-grow: 1;

    &-2x {
      flex-grow: 2;
    }
  }
}
