.map-component {
  #google-map {
    width: 100% !important;
  }

  &.is-safari {
    .gm-style-iw-c {
      box-shadow: none !important;
    }
  }

  @include mobile {
    display: flex;
    justify-content: center;
    height: auto;
  }
  @include tablet-only {
    position: sticky;
    top: 1rem;
  }
  @include touch {
    justify-content: center;
    width: 100%;
    .gm-style-iw-c {
      max-height: calc(100% + 14rem) !important;
    }
  }
  @include desktop {
    position: sticky;
    top: 1rem;
  }
}

.gm-style-iw,
.gm-style-iw-d {
  padding: 0 !important;
  overflow: hidden !important;
  max-height: none !important;
}

.gm-style-iw-t::after {
  z-index: -1;
}
