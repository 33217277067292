.send-icon-card{
  align-items: center;
  background-color: $getphy-light-background;
  border-radius: 10px;
  box-shadow: $box-shadow;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 4vh;
  min-height: calc(320px - 4vh);
  padding:1.5rem;
  width: 294px;

  .send-icon-title{
    color: $getphy-main-text;
    font-size: 22px;
    text-align:center;
  }

  .send-icon-card-picture {
    height: 120px;
  }

  .send-icon-picture {
    max-height: 100%;
  }
}
