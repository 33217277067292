@use 'sass:color';
@import '@app/styles/variables';

.navigation-pills {
  display: flex;
  justify-content: flex-start;

  .pills-link {
    position: relative;
    border-color: $getphy-secondary;
    color: $getphy-secondary;
    font-weight: 700;
    border: 3px solid;
    padding: 0.3rem 1rem 0.3rem 1rem;
    &:hover {
      background-color: $getphy-secondary;
      border: 3px solid $getphy-secondary;
      color: $color-white;
    }
  }

  .left-pills {
    border-radius: 50px 0 0 50px;
    min-width: 120px;
  }

  .middle-pills {
    left: -2px;
    min-width: 120px;
    border-left: 0;
    &:hover {
      border-left: 0;
    }
  }

  .right-pills {
    border-radius: 0 50px 50px 0;
    left: -4px;
    min-width: 120px;
    border-left: 0;
    &:hover {
      border-left: 0;
    }
  }

  .is-selected {
    background-color: $getphy-secondary;
    border-color: $getphy-secondary;
    color: $color-white;

    &:hover {
      background-color: color.adjust($getphy-secondary, $lightness: -5%);
    }
  }

  .is-disabled {
    background-color: $color-white;
    color: $color-gray;
    pointer-events: none;
  }
}
