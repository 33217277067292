.date-time-picker-component {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: 59px;
  width: 100%;
  user-select: none;
  cursor: pointer;

  .date-time-picker-wrapper {
    border: 1px solid $primary;
    font-family: $family-sans-serif;
    font-size: 1.4rem;
    font-weight: $weight-bold;
    align-items: center;
    background-color: $white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    min-height: inherit;
    padding: 0.8rem;
    position: relative;
    width: 100%;

    @include desktop {
      font-family: $family-sans-serif;
      font-size: 2rem;
      font-weight: $weight-bold;
      padding: 1rem 2rem;
    }
  }

  .date-time-picker-container {
    display: flex;
    flex-direction: column;
    height: 0;
    margin-top: 5px;
    max-height: 410px;
    text-align: left;
    overflow-y: hidden;
    // transition: all 0.3s ease-in-out;

    &.close-container {
      height: 0;
    }
  }

  .separator {
    display: block;
    width: calc(100% - 32px);
    margin: 16px;
    border-bottom: 1px solid $color-gray;
  }

  .icon-chevron {
    width: 25px;
    height: 12px;
    flex-shrink: 0;
    margin-left: 7px;
    transition: transform .5s ease-in-out;
    transform: rotate(0deg);
  }

  &.is-disabled {
    color: $color-gray;
    cursor: not-allowed;

    .date-time-picker-wrapper {
      background-color: $color-gray;
    }

    .input-date-picker {
      background-color: whitesmoke;
      border: none !important;
      color: #7a7a7a !important;
    }
  }

  .date-time-picker-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    color: $primary;

    .icon-chevron {
      margin-left: 10px;
    }
  }

  &.is-open {
    .date-time-picker-wrapper {
      border: 1px solid $primary;
      box-shadow: $box-shadow;
      height: auto;
      margin-bottom: 30px;
      position: absolute;
      top: 60px;
      z-index: 1000;
    }

    .date-time-picker-container {
      height: auto;
      width: 100%;
    }

    .icon-chevron {
      transform: rotate(180deg);
    }
  }

  .field-input-datetime {
    width: 100%;
  }

  .field-border {
    display: flex;
    flex-direction: column;
    .field-label {
      margin-right: 0;
      text-align: left;
      color: $getphy-label-text;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
    .input-date-picker {
      border: 1px solid $color-gray;
      border-radius: 5px;
      width: 100%;
      height: 2.5em;
      padding-bottom: calc(0.5em - 1px);
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
      padding-top: calc(0.5em - 1px);
      font-size: 16px;
      &:hover {
        border-color: #b5b5b5;
      }
      &:focus-visible {
        outline: none;
      }
      &:focus {
        border-color: #485fc7;
        box-shadow: 0 0 0 0.125em rgb(72 95 199 / 25%);
      }
    }
  }

  &.is-error {
    .field-label {
      color: $getphy-error;
    }
    .input-text {
      border-color: $getphy-error;
    }
    .field-border {
      .input-date-picker {
        /* stylelint-disable-next-line max-nesting-depth */
        &:focus {
          border-color: $getphy-error;
          box-shadow: 0 0 0 0.125em rgb(244 67 54 / 25%);
        }
      }
    }
  }

}
