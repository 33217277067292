.edit-cheque {
  .button.is-outlined {
    margin-bottom: 2rem;
  }

  .add-cheque-header {
    display: flex;
    flex-direction: column;
  }

  .react-datepicker-popper {
    z-index: 6;
  }

  .edit-cheque-title {
    margin: 1rem 0 2rem;
    display: flex;
    justify-content: center;
  }

  .field-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .field-cheque {
      width: 100%;
      &:first-child {
        margin-right: 2rem;
      }
      input {
        border: 1px solid #dbdbdb;
      }
      input::placeholder {
        color: hsl(0, 0%, 50%);
      }
      input.is-danger {
        border-color: $getphy-error;
      }
      [disabled].input {
        background-color: hsl(0, 0%, 95%);
      }
    }
    @include mobile {
      flex-direction: column;
      margin-bottom: 0.75rem;
      .field-cheque:first-child {
        margin-right: 0;
      }
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }

  .registereds-loading {
    height: 40px;
    margin-top: 42px;
  }

  .validation-button {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }

  .validation-vertical-button {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;

    .button {
      margin-bottom: 1rem;
      width: 40%;
    }
  }

  .overpayment-cheque {
    max-width: 60%;
    background: $color-payment-finalise;
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: $color-dark-gray;
    font-size: 0.9rem;
    padding: 10px;
    margin-top: 2rem;

    .icon-search {
      float: left;
      height: 1.5em;
      margin: 10px 15px 10px 5px;
    }

    .icon-warning {
      margin-right: 0.5rem;
    }
  }

  .create-cheque-loader {
    width: 100%;
  }

  .btn-go-back {
    position: absolute;
    top: 0;
    left: 0;
  }
}
