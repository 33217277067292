@import '@app/styles/variables';

.participant-indicator {
  background-color: $color-gray-darker;
  display: flex;
  height: 24px;
  margin-right: 12px;
  width: 12px;

  &.beneficiary {
    background-color: $getphy-accentuated;
  }

  &.participant-tab {
    height: 30px;
    width: 15px;
  }

  &.people-search {
    margin: 0px auto;
  }
}
