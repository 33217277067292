@import '@app/styles/variables';

.pagination {
  .pagination-previous-next,
  .pagination-previous-next:focus:not(:active) {
    border: unset;
    box-shadow: none;
  }

  .pagination-link {
    @include mobile {
      min-width: 2em;
      height: 2em;
    }
  }

  .list-page {
    display: flex;
    font-weight: $weight-regular;
  }

  .button {
    background: transparent;
    border: none;
    border-radius: 50%;
    color: $color-black;

    &.is-current {
      color: $color-white;
      background-color: $getphy-secondary;
    }

  }

  button.pagination-previous-next {
    width: 2rem;
    height: 2rem;

    .icon-chevron {
      width: 0.5rem;
    }

    &:hover {
      .icon-chevron {
        fill: $color-white;
      }
    }
  }
}
