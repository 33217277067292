@import '@app/styles/variables';
@import '@app/styles/mixins';

.bank-deposit {
  .bank-deposit-container {
    padding: 1rem 0 2rem;
  }

  .table {
    table-layout: auto;
  }

  .bank-deposit-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  .edit-bank-deposit-fields {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 2rem;
    width: 60%;
  }

  .header-fields {
    margin-top: 2rem;
    margin-bottom: 4rem;
    width: 100%;
    display: flex;
    .fields {
      width: 100%;
      max-width: 70%;
      margin: 1rem auto;
      @include widescreen {
        padding-top: 1rem;
      }
      &:first-child {
        margin-right: 1rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include mobile {
      flex-direction: column;
      .fields:first-child {
        margin-right: auto;
      }
    }
  }

  .cheques-loading {
    height: 80px;
  }

  .button-action-remove {
    background-color: transparent;
    border: none;
  }

  .icon-action-bank-deposit {
    fill: $getphy-accentuated;
    cursor: pointer;
    width: 1rem;
  }

  .validation-button {
    display: flex;
    justify-content: center;
  }

  .bank-deposit-title {
    text-align: center;
  }

  .btn-go-back {
    position: absolute;
    top: 1rem;
    left: 0;
  }

}
