@import '@app/styles/variables';

.page-not-found {
  text-align: center;

  .title {
    color: $getphy-primary;
    margin-top: 2rem;
  }

  .bloc-sentence {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
  }

  .sentence {
    color: $getphy-main-text;
    font-size: 1rem;
    font-weight: 700;
  }

  .link {
    text-decoration: underline;
  }
}
