.search-loading {
  background-color: $getphy-light-background;
  width: 75vw;
  height: 80px;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  display: flex;

  @include mobile {
    width: 100%;
  }
}

.search-container {
  background-color: $getphy-light-background;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  @include touch {
    flex-direction: column;
  }

  .block-search-city {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include touch {
      margin-bottom: 1rem;
      flex-wrap: wrap;
    }

    @include mobile {
      flex-direction: column;
      margin-bottom: 0;
    }

    .search-field {
      width: 100%;
    }
  }

  .search-field-input-map {
    @include touch {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }

  .search-field-input {
    width: 49%;
    margin-bottom: 0;
    margin-right: 1rem;

    @include touch {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }

  .search-filters {
    color: $getphy-main-text;
  }
}

.find-group-classes {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include touch {
    flex-direction: column;
    align-items: center;
  }

  @include mobile {
    align-items: flex-start;
  }

  .search-results {
    color: $getphy-grey-text;
    margin-top: -1rem;
  }

  .faq-toggle-btn {
    a:hover {
      color: $getphy-secondary;
    }
    color: $getphy-secondary;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
}
