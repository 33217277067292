@charset "utf-8";

// Getphy Style Guide

$getphy-primary: #0380cf;
$getphy-secondary: #006cb0;
$getphy-secondary-transparent: #006cb032;
$getphy-accentuated: #ffb401;
$getphy-blue-light: #ebf8ff;
$getphy-blue-medium: #3182CE;

$getphy-light-background: #ebf3ff;

$getphy-main-text: #000060;
$getphy-grey-text: #6e6e6e;
$getphy-grey-text-secondary: #8F8F8F;
$getphy-label-text: #4d4d8b;
$getphy-error: #f44336;
$getphy-error-transparent: #f4433659;
$getphy-inactive-element: #ddd;
$getphy-green-element: #399448;

$color-white: #fff;
$color-black: #000;
$color-dark-gray: #363636;
$color-gray-light: #CECECE;
$color-gray: #dbdbdb;
$color-gray-darker: #A4A4A4;
$color-gray-chalk: #4a4a4a;

$color-payment-refused: #fcc4c0;
$color-payment-waiting: #fcc4c0;
$color-payment-abandoned: #faaca7;
$color-payment-finalise: #ffe7af;
$color-payment-accepted: #c1ddc6;
$color-payment-overpaid: #b0d7f0;
$color-payment-unsubscribed: rgba($color-black, 0.25);

$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;

$width-screen-payment: 1000px;

$primary: $getphy-primary;
$primary-invert: findColorInvert($primary);

$danger: $getphy-error;

$secondary: $getphy-secondary;
$secondary-invert: findColorInvert($secondary);
$accentuated: $getphy-accentuated;
$accentuated-invert: $getphy-main-text;
$label: $getphy-label-text;

$family-serif: 'Roboto', 'Georgia', serif;
$family-sans-serif: 'Roboto', sans-serif;

$family-primary: $family-serif;

$box-shadow: 0 8px 16px 5px rgb(0 0 0 / 20%);
