.g-label {
  color: $getphy-label-text;
  font-style: normal;
  font-weight: 700;

  &:hover {
    color: $getphy-label-text;
    font-style: normal;
    font-weight: 700;
  }
}

.g-form-field {
  border-radius: 5px;
}
