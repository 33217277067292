.coaches-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .title-two {
    color: $getphy-primary;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;

    @include mobile {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .coaches-icons {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    margin: 1.75rem 0 0.5rem 0;
    width: 25%;

    @include mobile {
      width: 100%;
    }

    &.coaches-one-icon {
      width: 100%;
    }
  }

  .coaches-half-block {
    @include mobile {
      width: 100%;
    }

    @include tablet {
      display: flex;
      justify-content: space-between;
      width: 50%;
      padding: 0 3rem;
    }
  }

  .coaches-coach {
    align-items: baseline;
    display: inline-flex;
    width: 100%;

    @include mobile {
      flex-direction: column;
      margin-bottom: 1rem;
    }

    .coaches-field {
      width: 50%;
      position: relative;

      .g-form-field {
        margin-right: 1.5rem;
      }

      @include mobile {
        width: 100%;
      }
    }

    .coaches-contact {
      @include mobile {
        width: 100%;
      }
    }
  }
}
