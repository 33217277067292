@import '@app/styles/variables';

.monetico-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  .monetico {
    .monetico-title {
      margin: 30px 0 30px 0;
      text-align: center;
    }

    .monetico-text {
      color: $getphy-main-text;
      text-align: center;
    }

    .monetico-icon {
      display: block;
      font-weight: bolder;
      height: 100px;
      margin: auto;
      width: 100px;
    }

    .monetico-button {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    .summary {
      margin: 50px 0 20px 0;
      border-bottom: 1px solid $getphy-main-text;
    }

    .monetico-table {
      color: $getphy-main-text;

      tbody td {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      tbody td:not(:last-child) {
        padding-right: 150px;
      }
    }
  }
}
