.trial-banners {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2.5rem;
  padding: 0 0.75rem;
  @include mobile {
    margin-bottom: 1.5rem;
  }
  h2 {
    margin-bottom: 2rem;
    color: $getphy-secondary;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.45rem;
    @include mobile {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.trial-banners-loading {
  display: flex;
  min-height: 80px;
}
