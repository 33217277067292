@import '@app/styles/variables';
@import '@app/styles/mixins';

// TODO: Refacto thoses styles, because here they are globals
.cell-txt-middle {
  vertical-align: middle;

  &.cell-txt-middle-amount {
    @include mobile {
      display: none;
    }
  }
}

.payment-table {
  margin: 40px 0;
  width: 100%;
  border-collapse: unset;

  tr.even {
    background-color: $getphy-light-background;
  }

  tr.odd {
    background-color: $color-white;
  }

  tr.group-class-total-row {
    border-top: 1px solid $color-gray;

    @include mobile {
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      .cell-padding-left {
        display: none;
      }
    }

    .cell-txt-middle {
      @include mobile {
        font-weight: 700;
        padding: 0;
      }
    }
  }

  tr.change-mobile {
    @include mobile {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0.5rem 0;
    }
  }

  .border-table-deposit tr {
    th:first-child {
      border-top-left-radius: 20px;
    }

    th:last-child {
      border-top-right-radius: 20px;
    }
  }

  tbody.border-table-deposit-content tr:last-child {
    td:first-child {
      border-bottom-left-radius: 20px;
    }

    td:last-child {
      border-bottom-right-radius: 20px;
    }
  }

  .button-period {
    width: 250px;
  }
}

.table-payment-container {
  tr.hide {
    display: none;
  }

  tr.show {
    display: 'table-row';
  }
}

.light-blue-border {
  border: 1px solid $getphy-light-background;
}

.drop-shadow {
  box-shadow: $box-shadow;
}

.rounded-corners {
  border-radius: 20px;
}

.bg-light-blue {
  background-color: $getphy-light-background;

  &.payment-table-label {
    @include mobile {
      display: none;
    }
  }
}

.bg-dark-blue {
  background-color: $getphy-primary;
  .group-class-payment-title {
    font-weight: 700;
  }
  @include desktop {
    .group-class-infos {
      display: flex;
      align-items: center;
      .group-class-payment-title::after {
        content: "-";
        padding: 1rem;
      }
    }
  }
}

.cell-padding-left {
  padding: 0.8rem 1.2rem;
  padding-left: 2rem;

  .groupe-class-payment-title {
    font-weight: 700;

    @include mobile {
      margin-bottom: 0.5rem;
    }
  }

  .group-class-payment-infos {
    @include mobile {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @include mobile {
      span {
        margin-bottom: 0.25rem;
      }

      .spaces {
        display: none;
      }
    }
  }
}

// TODO: Refacto thoses styles, because here they are globals
.cell-padding {
  padding: 0.8rem 1.2rem;
}

.cell-txt-centered {
  text-align: center;
  vertical-align: middle;
}

.txt-main {
  color: $getphy-main-text;
  font-weight: bold;
}

.txt-white {
  color: $color-white;
}

.txt-grey {
  color: $color-gray-chalk;
}