.marker-popup {
  position: relative;
  display: flex;
  box-shadow: $box-shadow;
  background-color: $getphy-light-background;

  .marker-popup-loading {
    padding: 1rem;
  }

  .marker-left-icon,
  .marker-right-icon {
    position: absolute;
    top: 165px;
    cursor: pointer;
  }

  .icon-circle-arrow {
    fill: $getphy-accentuated;
    border-radius: 50%;
    background-color: $color-white;
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;
  }

  .position-left {
    left: 5px;
  }

  .position-right {
    right: 5px;
  }

  .group-class-card-item {
    box-shadow: none;
    margin-top: 0;

    .thumbnail {
      border-radius: 0;
    }

    &.group-class-card-hide-image .group-class-card-image {
      @include touch {
        display: none;
      }
    }
  }
}
