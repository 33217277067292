// TODO: Refacto thoses styles, because here they are globals
.season-dates {
  .date-picker-field {
    margin-right: 1.5rem;
    width: 50%;
  }
}

.periods-container {
  display: inline-flex;
  margin-bottom: 1.5rem;
  width: 100%;

  @include touch {
    display: flex;
    flex-direction: column;
  }

  .periods {
    display: flex;
    justify-content: space-between;
    width: 50%;

    @include touch {
      width: 100%;
    }

    @include mobile {
      flex-direction: column;
    }

    .period-end-date {
      margin-bottom: 0.75rem;
      margin-top: 2rem;

      @include mobile {
        display: block;
        margin-top: 0;
      }
    }
  }

  .periods-first-block {
    @include desktop {
      justify-content: space-between;
    }

    .date-picker-field {
      margin-right: 1.5rem;
      width: 50%;
    }
  }

  .periods-second-block {
    padding: 0 3rem;
  }
}

// TODO: Refacto thoses styles, because here they are globals
.periods-icons {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin: 1.75rem 0 0.5rem 0;
  width: 25%;

  @include mobile {
    width: 100%;
  }

  .periods-icon {
    cursor: pointer;
  }

  &.periods-one-icon {
    width: 100%;
  }
}

.periods-title {
  color: $getphy-primary;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @include mobile {
    margin-top: 1rem;
    text-align: center;
  }
}
