.add-cheques-bank-deposit {
  margin: 0 auto;
  padding-top: 2rem;

  @include widescreen {
    width: $width-screen-payment;
  }

  .bank-deposit-information {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 60%;
  }

  .validation-button {
    display:flex;
    justify-content: center;
  }

  .add-cheques-bank-deposit-header {
    margin-bottom: 2rem;
  }

  .add-cheques-bank-deposit-title {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .icon-action-bank-deposit {
    fill: $getphy-accentuated;
    cursor: pointer;
    width: 1rem;
  }

  .bank-deposit-information-text {
    color: $getphy-main-text;
    font-size: 1rem;
    font-weight: 700;
  }

}
