.modal-container {
  .card{
    border-radius: 10px;
    max-width: 90%;
  }

  .modal-header {
    background-color: white;
    padding: 2rem 2rem 0 2rem;
    border-color: transparent;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
  }

  .body {
    background-color: $color-white;
    padding: 0 2rem 1rem 2rem;
    color: $getphy-main-text;

    @include widescreen {
      padding: 0 2rem 2rem 2rem;
    }
  }

  .title {
    font-size: 1.75rem;
    color: $getphy-main-text;
    font-weight: $weight-medium;
  }

  .icon {
    font-size: 1.7rem;
    &:hover {
      cursor: pointer;
    }
  }

  .modal-footer {
    background-color: $color-white;
    border-color: transparent;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;

    .confirm {
      margin-left: 1rem;
    }
  }

  .custom-error-message {
    margin-bottom: 0 !important;
  }
}

// Custom ol numbers
ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  li {
    counter-increment: my-awesome-counter;
    padding-left: 45px;
    position: relative;
    margin: 1.5rem 0;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: counter(my-awesome-counter);
      color: $getphy-main-text;
      font-weight: bold;
      width: 30px;
      height: 30px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      left: 0;
      top: -5px;
      border: 2px solid $getphy-main-text;
    }
    &:first-child::before {
      background-color: $getphy-main-text;
      color: $color-white;
    }
  }
}
