.description-container {
  border: 2.5px solid $getphy-primary;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  padding: 1rem;

  .description-container-title {
    background-color: $color-white;
    color: $getphy-label-text;
    display: inline-flex;
    font-weight: 700;
    left: -1.2rem;
    padding-bottom: 1em;
    padding-right: 1em;
    position: relative;
    top: -2rem;
  }

  .description-container-value {
    color: $getphy-main-text;
    font-weight: 400;
    position: relative;
    top: -1rem;
    word-wrap: break-word;
  }
}
