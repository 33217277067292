.group-class-peoples {
  background-color: $getphy-light-background;
  bottom: 0;
  left: 0;
  padding-bottom: 1rem;
  padding-top: 3rem;
  position: absolute;
  top: 19rem;
  width: 15rem;
  z-index: 1;

  @include touch {
    padding-top: 7rem;
    top: 12rem;
  }

  @include mobile {
    padding-top: 0;
    position: initial;
    width: 100%;
    z-index: 1;
    &.closed {
      max-height: 4rem;
      overflow: hidden;
    }
  }

  .group-class-peoples-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .list-registereds-line-people {
    align-items: center;
    display: inline-flex;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: nowrap;
  }

  .is-selected {
    background-color: $getphy-secondary-transparent;
  }

  .group-class-peoples-title-one {
    color: $getphy-main-text;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 1.75rem;
    font-style: normal;
    line-height: 2.45rem;

    @include mobile {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      line-height: 4rem;
      margin-bottom: 0;
    }

    .group-class-peoples-chevron {
      display: none;
      @include mobile {
        display: block;
      }
    }
  }

  .group-class-peoples-lists {
    @include scroll-bar;
    height: 77%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 1rem;
    padding-top: 1rem;
    z-index: 2;

    @include mobile {
      overflow: auto;
    }

    .group-class-peoples-add {
      margin-bottom: 2.5rem;
      width: 100%;
    }

    .title-two {
      color: $color-black;
      display: inline-block;
      font-size: 1.15rem;
      font-weight: bold;
      padding-right: 1rem;
      position: relative;

      &.registered {
        margin-top: 1.25rem;
      }
    }

    .list-registereds-users-registereds {
      list-style: none;
      margin: 0;
    }

    .list-requests-header {
      padding: 0 1rem;
    }

    .list-requests-line-people {
      align-items: center;
      display: inline-flex;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 1rem;
    }

    .list-requests-name {
      max-width: 11rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.people {
  color: $getphy-main-text;
  cursor: pointer;
  font-weight: bold;
  line-height: 3rem;

  &:hover {
    background-color: $getphy-secondary-transparent;
  }

  .list-registereds-name {
    max-width: 11rem;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mobile {
      max-width: 100%;
    }
  }

  .icon {
    height: 1.5rem;
    margin-right: 0.5rem;
    width: 1rem;
  }
}

.peoples-container {
  padding: 0;
  width: calc(100% - 15rem);

  @include until-widescreen {
    padding: 1rem;
  }

  @include mobile {
    margin-top: 0;
    padding: 0;
    width: calc(100% - 3rem);
  }

  @include widescreen {
    padding: 3rem;
    padding-top: 1rem;
  }

  .group-class-peoples-menu {
    @include desktop {
      padding-left: 4em;
    }

    li {
      color: $getphy-main-text;
      list-style: inside;
      margin-top: 1.5em;
    }
  }
  .title-one {
    color: $getphy-main-text;
    margin-bottom: 3rem;
  }

  .pagination-block {
    margin-bottom: 0;
  }
}
