@import '@app/styles/variables';

.page-contact {
  width: 500px;
  margin: 0 auto;
  padding-top: 4rem;
  text-align: center;

  h1 {
    margin-bottom: 1rem;
  }

  .subject-field {
    text-align: left;
  }
}
