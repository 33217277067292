.calendar {
  display: flex;
  flex-direction: column;
  user-select: none;

  .calendar-cell {
    font-family: $family-sans-serif;
    font-size: 1.4rem;
    font-weight: $weight-light;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: calc(100% / 7);
    height: 30px;
    cursor: pointer;

    @include desktop {
      font-family: $family-sans-serif;
      font-size: 1.6rem;
      font-weight: $weight-light;
    }

    &:hover {
      background-color: rgba($color-gray, 0.4);
    }

    &.is-disabled {
      background-color: rgba($color-gray, 0.2);
      opacity: 0.2;
      cursor: not-allowed;
    }

    &.no-value {
      background-color: transparent;
      cursor: default;
    }
  }

  .calendar-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    &.header {
      height: 30px;
      margin-bottom: 8px;
      padding: unset;

      .calendar-cell {
        font-weight: $weight-bold;
        cursor: default;
      }
    }
  }

  .calendar-current {
    position: absolute;
    top: 2px;
    left: 2px;
    border: 2px solid rgba($primary, 0.4);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: rgba($primary, 0.3);
  }
}
