.tabs-container {
  z-index: 1;
  position: relative;

  .tabs-container-tabs {
    align-items: stretch;
    display: flex;
    height: 4rem;
    justify-content: space-around;
    margin: 0 auto;
    width: 960px;

    @include touch {
      width: 100%;
    }
  }
}
