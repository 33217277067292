@import '@app/styles/variables';

.status-block {
  border: 1px solid $getphy-grey-text;
  padding: 0.3rem;
  text-align: center;

  &.status-block-pending {
    background-color: $color-payment-waiting;
  }

  &.status-block-to-finalize {
    background-color: $color-payment-finalise;
  }

  &.status-block-accepted {
    background-color: $color-payment-accepted;
  }

  &.status-block-overpaid {
    background-color: $color-payment-overpaid;
  }

  &.status-block-unsubscribed, .status-block-not-applicable {
    background-color: $color-payment-unsubscribed;
  }
}
