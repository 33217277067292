.edit-container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  @include touch {
    flex-direction: column;
    flex-wrap: wrap;
  }

  @include desktop {
    .half-block {
      margin-bottom: 2rem;
      min-width: 50%;

      &:first-child {
        padding-right: 2rem;
      }
    }
  }

  .edit-title {
    color: $getphy-primary;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;

    @include mobile {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .info {
    bottom: -1.25rem;
    position: absolute;
    right: 0;
  }

  .edit-form {
    width: 100%;
  }

  .edit-description {
    border: 2px solid $getphy-primary;
    border-radius: 10px;
    margin-top: 0.75rem;
    position: relative;

    &.field-border-error {
      border-color: $getphy-error;
    }

    .textarea {
      z-index: 1;
      margin-top: 0.75rem;
      border: none;
      box-shadow: none;
      padding-top: 0;
    }

    .edit-label {
      background: $color-white;
      left: -0.5rem;
      margin-bottom: 0;
      padding: 0 0.75rem;
      position: absolute;
      top: -0.75rem;
      z-index: 4;
    }
  }

  .edit-two-sides {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    @include mobile {
      flex-direction: column;
    }

    @include touch {
      margin-bottom: 0;
    }

    &.timers {
      justify-content: space-between;
      max-width: 45%;
      min-width: 40%;
      .edit-label {
        margin-bottom: 0.5em;
      }
    }

    #startTime {
      margin-right: 1rem;

      @include mobile {
        margin-right: 0;
      }
    }

    .field {
      margin-bottom: 0.75rem;
      min-width: 45%;
    }

    .sub {
      display: flex;
      justify-content: space-between;
      width: 50%;

      @include mobile {
        width: 100%;
      }
    }
  }

  .edit-line-buttons-for-mobile {
    display: none;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 75%;

    @include mobile {
      display: flex;
      flex-direction: column;

      .edit-cancel {
        margin-bottom: 0.75rem;
      }
    }
  }

  .edit-line-buttons {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    margin-top: 2rem;
    width: 75%;

    @include mobile {
      flex-direction: column;

      .cancel {
        margin-bottom: 0.75rem;
      }
    }
  }

  .edit-address {
    max-width: 42%;
    width: 100%;

    @include mobile {
      max-width: 100%;
    }
  }

  .icons {
    align-items: center;
    display: inline-flex;
    justify-content: space-evenly;
    width: 25%;

    @include mobile {
      width: 100%;
    }

    .icon {
      cursor: pointer;
    }

    &.only-one-icon {
      width: 100%;
    }
  }

  .icon-plus {
    color: $getphy-main-text;
    max-width: 1rem;
  }

  .season-dates {
    display: inline-flex;
    justify-content: space-evenly;
    margin-top: 1rem;
    width: 100%;

    @include desktop {
      justify-content: space-between;
      width: 50%;
    }

    @include mobile {
      display: block;
    }
  }

  .loading,
  .loader {
    height: 5rem;
  }

  .edit-google-address-autocomplete,
  .edit-help {
    margin-bottom: 0.75rem;
  }

  .edit-no-margin-bottom {
    margin-bottom: 0;
  }
}
