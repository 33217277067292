@import '@app/styles/variables';
@import '@app/styles/mixins';

.form-container {
  background-color: $getphy-light-background;
  border-radius: 10px;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  padding: 1.3rem;

  .title {
    color: $getphy-main-text;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .button-container .button {
    &:last-child {
      margin-right: unset;
    }

    @include touch {
      width: 100%;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: unset;
      }
    }
  }

  .card-form-footer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: $getphy-light-background;

    .button-container {
      margin-top: 0.5rem;
      width: 100%;
    }

    @include touch {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .card-form-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;

    @include touch {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .card-form-body {
    width: 100%;
  }

  .form-container-loader {
    height: 1rem;
  }
}
