.toggle-button {
  align-items: center;
  display: flex;
  width: 70%;
  margin-left: 1rem;
  justify-content: flex-end;

  .toggle-button-toggle {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.75rem;
    padding: 0;
    position: relative;
    touch-action: pan-x;
    user-select: none;
  }

  .input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .background {
    background-color: $color-white;
    border: 2px solid $getphy-secondary;
    border-radius: 30px;
    height: 22px;
    padding: 0;
    transition: all 0.2s ease;
    width: 40px;
  }

  .circle {
    background-color: $getphy-secondary;
    border-radius: 50%;
    box-sizing: border-box;
    height: 16px;
    left: 4px;
    position: absolute;
    top: 3px;
    transition: all 0.25s ease;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    width: 16px;
  }

  .checked {
    .circle {
      background-color: $color-white;
      border: 0;
      left: 21px;
    }

    .background {
      background-color: $getphy-secondary;
    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
  }
}
