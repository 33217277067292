.group-class-detailed-show {
  .group-class-detailed-show-title {
    color: $getphy-primary;
    font-size: 1.25rem;
    font-weight: 700;
    @include mobile {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .group-class-detailed-show-coach, .period-table-columns {
    padding-bottom: 2rem !important;
    border-bottom: 1px solid $color-gray;
    margin-bottom: 2rem;
  }

  .class-type {
    text-transform: capitalize;
  }

  .half-width{

    @include desktop {
      width: 50%;
      padding-right: 3rem;
    }
  }

  .container-flex {
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    @include touch {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .show-description {
    border: 2px solid $getphy-primary;
    border-radius: 10px;
    margin-top: 0.75rem;
    position: relative;

    .textarea {
      z-index: 1;
    }

    .label {
      background: $color-white;
      left: -0.5rem;
      margin-bottom: 0;
      padding: 0 0.75rem;
      position: absolute;
      top: -0.75rem;
      z-index: 4;
    }
  }

  .show-start-date {
    padding-right: 4rem;

    @include touch {
      padding-right: 0;
    }
  }

  .show-label-full-block {
    justify-content: unset;
  }
}
