.tab-element {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  text-align: center;
  &.is-active {
    border-bottom: 5px solid $getphy-primary;
  }

  .tab-element-badge{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 10px;
    border-radius: 18px;
    background-color: $getphy-accentuated;
    color: $getphy-main-text;
    font-weight: $weight-medium;
    transform: translateY(-.5rem);
  }

  .tab-element-link {
    color: $getphy-grey-text;
    font-family: $family-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    border-bottom: none;
    padding: 0.5em 1em;
    margin-bottom: -1px;
    &.is-active {
      transform: translateY(3px);
      color: $getphy-primary !important;
    }
  }
}
