.header-banner {
  background-color: $getphy-light-background;
  color: $getphy-main-text;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    position: absolute;
    right: 5px;
    top: 136px;
    width: 38px;
    height: 38px;
    cursor: pointer;
    border-radius: 50%;
    background-color: transparent;
    @include tablet {
      top: 115px;
      right: 32px;
    }
  }

  .close:hover {
    opacity: 0.5;
  }

  .close:before, .close:after {
    position: absolute;
    top: 8px;
    left: 18px;
    content: " ";
    height: 18px;
    width: 1px;
    background-color: $color-gray-chalk;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    max-width: 260px;
    @include tablet {
      max-width: 620px;
    }

    .icon {
      width: 1.7rem;
      fill: $getphy-blue-medium;
      margin-right: 0.3rem;
    }

    .cta {
      cursor: pointer;
      margin-left: .2rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
