.menu-container {
  border: 1px solid;
  background: $getphy-secondary;
  left: 0;
  position: absolute;
  top: 8rem;
  transition: all 0.25s ease-out;

  &.is-active {
    bottom: 0;
    height: calc(100% - 8rem);
    opacity: 1;
    width: 20rem;
    z-index: 7;
  }
  &.is-not-active {
    height: 0;
    opacity: 0;
    width: 0;
    z-index: -2;
  }

  .icon-close {
    color: $color-white;
    cursor: pointer;
    margin: 1rem;
  }

  .menu-link {
    align-items: center;
    border-radius: 0;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0.75rem;
    text-transform: uppercase;

    &.is-active {
      background-color: $getphy-light-background;
      color: $getphy-main-text;
    }
  }
}
