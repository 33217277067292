.coach-container {
  width: 100%;

  .coach-table {
    color: $getphy-label-text;
    display: flex;
    justify-content: space-around;

    @include mobile {
      flex-direction: column;
    }

    .coach-table-title {
      color: $getphy-label-text;
      font-weight: 700;
    }
  }
}
