.group-classes-container {
  padding: 2rem 0;

  .col-map-items {
    padding: 0;
  }

  .column-no-result {
    padding-top: 0;
    .no-result {
      color: $getphy-grey-text;
      font-weight: 700;
      margin-top: 2rem;
      display: inline-block;
      font-size: 19px;
    }
  }
}

.group-classes-validation-message {
  color: $getphy-primary;
  font-weight: 700;
  margin-top: 1rem;
}

.group-classes-no-block {
  align-items: center;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: column-reverse;

  @include desktop {
    align-items: flex-end;
  }

}

.group-classes-block {
  margin-bottom: 2rem;
  .columns-block-classname {
    flex-direction: row-reverse;
    @include touch {
      flex-direction: column;
      margin: 0;
    }
  }
}

.group-classes-no-map {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.group-classes-no-group {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.group-classes-loader {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 80px;
}

.group-classes-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.group-classes-results {
  display: flex;
  justify-content: space-between;
  .search-results {
    min-width: 120px;
  }
}

.filter {
  border-radius: 2px;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  background-color: $getphy-light-background;
  color: $getphy-primary;
  .icon {
    fill: $getphy-primary;
  }
}

.filter-selected {
  background-color: #fff;
  outline: 2px solid $getphy-primary;
  outline-offset: 1px;
}

.online-hint {
  cursor: pointer;
  color: $getphy-primary;
}
