.group-class {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 1.5rem;

  .group-class-content {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
    width: 100%;

    @include mobile {
      align-items: center;
      display: flex;
    }

    .name {
      color: $getphy-secondary;
      font-weight: bold;
      margin-bottom: 0.25rem;
      text-transform: uppercase;
    }

    .buttons {
      display: flex;
      justify-content: space-evenly;
      margin-top: 1rem;
      @include touch {
        display: none;
      }
    }
  }

  .navigation-tab {
    background-color: $getphy-light-background;
    display: none;
    width: 100%;
    margin-bottom: 2rem;
    z-index:1;

    @include desktop {
      display: block;
    }
  }

  .header {
    align-items: center;
    background: $getphy-light-background;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    z-index: 2;

    @include desktop {
      margin-bottom: 0;
    }

    .header-title {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .block-desktop {
      margin-bottom: 1rem;
      margin-top: 1rem;
      text-align: center;
      @include desktop {
        width: 60%;
      }
      @include fullhd {
        width: 70%;
      }

      @include mobile {
        max-width: 80%;
      }

    }

    .title-address {

      @include mobile {
        width: 80%;
        margin: 0 auto;
      }
    }

    .icon-burger-menu {
      stroke: $getphy-secondary;
      margin-right: 1rem;
      cursor: pointer;
      position: absolute;
      left: 0;
      margin-left: 1.5rem;

      &:hover {
        stroke: rgba($getphy-secondary, 0.9);
      }

      @include desktop {
        display: none;
      }
    }
  }

  .icon-trash, .icon-pencil {
    cursor: pointer;
    max-width: 1rem;
    &.icon-disabled {
      cursor: not-allowed;
    }
  }

  .icon-eye {
    cursor: pointer;
    max-width: 1.25rem;
  }
}
