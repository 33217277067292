@import '@app/styles/variables';

.payment-deposit {

  .border-table-deposit-bank {
    overflow: hidden;
  }

  .button-validate {
    display:flex;
    justify-content: center;
    width: 100%;
  }

}
