.month-picker {
  display: flex;
  flex-direction: column;
  user-select: none;

  .month-picker-year {
    font-family: $family-sans-serif;
    font-size: 1.4rem;
    font-weight: $weight-light;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include desktop {
      font-family: $family-sans-serif;
      font-size: 2rem;
      font-weight: $weight-light;
    }
  }

  .month-picker-year-value {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 32px;

    &.with-year {
      cursor: pointer;

      &:hover {
        background-color: rgba($color-gray, 0.2);
      }
    }
  }

  .icon-arrow {
    display: flex;
    flex-shrink: 0;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    padding: 8px;
    cursor: pointer;
  }

  .arrow-previous {
    transform: rotate(180deg);
    margin-right: 16px;
  }

  .arrow-next {
    margin-left: 16px;
  }

  .month-picker-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .month-picker-item {
    font-family: $family-sans-serif;
    font-size: 1.4rem;
    font-weight: $weight-light;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    height: 40px;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-gray, 0.2);
    }

    @include desktop {
      font-family: $family-sans-serif;
      font-size: 1.6rem;
      font-weight: $weight-light;
    }
  }

  .month-current {
    position: absolute;
    top: 2px;
    left: 2px;
    border: 2px solid rgba($primary, 0.4);
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background-color: rgba($primary, 0.3);
  }
}
