// tablet breakpoint

$tablet: 768px;

// buttons
$button-border-width: 3px;

// navbar
$navbar-height: 6.313rem;

// footer
$footer-padding: 1.5rem;

// navbar
// breakpoint is on desktop
$navbar-breakpoint: 1024px;

//modal background
$modal-background-background-color: rgba(0, 0, 0, 0.42);
