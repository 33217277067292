.rotate-screen {
  position: fixed;
  top: 2rem;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc( 100vh - 4rem );
  z-index: 101; // one more than overlay
  color: $color-white;
  text-align: center;
  font-size: 22px;

  .icon-rotate-screen {
    fill: $color-white;
    height: 100px;
    width: 100px;
    margin-bottom: 2rem;
  }

  &.rotate-screen-edge {
    opacity: 0;
    pointer-events: none;

    @include touch {
      opacity: 1;
      pointer-events: inherit;
    }
  }
}
