.group-class-search-people {
  color: $getphy-main-text;

  .search-field {
    width: 50%;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    @include touch {
      width: 100%;
    }
    .icon-search {
      width: 1em;
      right: 15px;
    }
  }

  .group-class-search-people-results {
    background-color: $white;
    padding: 1rem;
  }

  .group-class-search-people-mention{
    margin-bottom: 1rem;
  }

  .table-results-block {
    overflow-x: auto;
  }

  .table-results {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .table-results-head {
    background-color: $getphy-primary;
  }

  .table-results-head-cell {
    padding: 0.5rem;
    border: 1px solid $getphy-light-background;
    color: $white;

    &:first-child {
      text-align: left;
    }
  }

  .table-results-body-row {
    cursor: pointer;

    &:nth-child(even) {
      background-color: $getphy-light-background;
    }

    &:hover {
      background-color: $color-gray-light;
    }
  }

  .table-results-body-row-cell {
    padding: 0.5rem;
    border: 1px solid $getphy-light-background;
    .icon-plus {
      max-width: 1rem;
    }
  }

  .block-loader,
  .table-loader {
    height: 5rem;
  }

  .not-exist-block {
    display: flex;
    align-items: center;

    .not-exist-text {
      margin-right: 2rem;
    }

    @include touch {
      flex-direction: column;
      align-items: unset;
      align-content: center;
    }
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;
  }
}
