@charset "utf-8";
@use 'sass:map';

/* FONTS */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@import '~@nodeModules/bulma/sass/utilities/initial-variables';
@import '~@nodeModules/bulma/sass/utilities/functions';
@import '~@nodeModules/react-datepicker/dist/react-datepicker';
@import '~@nodeModules/react-datepicker/dist/react-datepicker-cssmodules';

@import 'variables';

@import '~@nodeModules/bulma/sass/utilities/derived-variables';

/* stylelint-disable */
$addColors: (
  'secondary': (
    $secondary,
    $secondary-invert,
    ),
    'accentuated': (
      $accentuated,
    $accentuated-invert,
  ),
);
/* stylelint-enable */

$colors: map.merge($colors, $addColors);

// bulma
@import '~@app/styles/bulma-variables';
@import '~@nodeModules/bulma/bulma';
@import '~@app/styles/forms';

@import 'common';

/* SHARED STYLES */
@import '~@header/styles/header';
@import '~@footer/styles/footer';
@import '~@shared/RotateScreen/styles/rotate-screen';
@import '~@shared/Button/styles/button';
@import '~@shared/Pagination/styles/pagination';
@import '~@shared/FormContainer/styles/form-container';
@import '~@shared/Payment/styles/payment-information';
@import '~@shared/Payment/styles/payment-form';
@import '~@shared/Personal/styles/personal-form';
@import '~@shared/Modal/styles/modal';
@import '~@shared/Modal/styles/modal-yes-no';
@import '~@shared/ToolTip/styles/tooltip';
@import '~@shared/DateTimePicker/styles/Calendar';
@import '~@shared/DateTimePicker/styles/DatePicker';
@import '~@shared/DateTimePicker/styles/DateTimePicker';
@import '~@shared/DateTimePicker/styles/MonthPicker';
@import '~@shared/DateTimePicker/styles/TimePicker';
@import '~@shared/DateTimePicker/styles/YearPicker';

/* COMMON STYLES */
@import '_common/components/map/styles/marker-popup';
@import '_common/components/phone-field/styles/flag-icon-option';
@import '_common/components/menu/styles/menu-container';
@import '~@shared/Image/image-loader';
@import '~@shared/ParticipantIndicator/styles/participant-indicator';

/* PAGES STYLES */
@import '~@groupClass/styles/group-class-detailed';
@import '~@search/styles/search-people';
@import '~@groupClass/styles/group-class-peoples';//A SUPPRIMER CA DEVIENT LE LAYOUT
@import '~@layout/styles/participants-layout';
@import '~@groupClass/styles/group-classes-cards-item';
@import '~@groupClass/styles/group-classes-registered';
@import '~@search/styles/search-field';
@import '~@banner/styles/title-banner';
@import '~@groupSend/styles/send-icon-card';
@import '~@groupSend/styles/group-class-send';
@import '~@session/styles/shared-sessions';
@import '~@beneficiary/styles/beneficiary-request-card';
@import '~@login/styles/login';
@import '~@login/styles/not-from-france';
@import '~@pagecontact/styles/page-contact';
@import '~@pagenotfound/styles/page-not-found';
@import '~@payment/styles/payments';
@import '~@payment/styles/card-item-payment-status';
@import '~@payment/styles/periods-selector';
@import '~@payment/styles/status-block';
@import '~@payment/styles/table-payment-container';
@import '~@payment/styles/payment-table';
@import '~@payment/styles/payment-deposit';
@import '~@payment/styles/bank-deposit';
@import '~@payment/styles/modal-payment';
@import '~@payment/styles/add-cheques-bank-deposit';
@import '~@payment/styles/edit-cheque';
@import '~@payment/styles/modal-add-cheque';
@import '~@payment/styles/monetico-page';
@import '~@payment/styles/custom-error';
@import '~@payment/styles/invoice-details';
@import '~@layout/styles/group-class-admin-header';
@import '~@groupClass/styles/modal-export';

/* NAVIGATION */
@import '~@shared/Navigation/TabElement/styles/tab-element';
@import '~@shared/Navigation/Tabs/styles/tabs';
@import '~@shared/Navigation/NavigationPills/styles/navigation-pills';

/* TOAST */
@import '_common/services/Toast/custom-toast';

/* EX MODULES */
@import '_common/components/DateTimePicker/styles/date-picker-field';
@import '_common/components/DateTimePicker/styles/time-picker';
@import '_common/components/description-container/description-container';
@import '_common/components/loader/loader-component';
@import '_common/components/map/styles/map';
@import '_common/components/notification-badge/notification-badge';
@import '_common/components/phone-field/phone-field';
@import '_common/components/radio-element/radio-element';
@import '_common/components/sub-text/sub-text';
@import '_common/components/text-body/text-body';
@import '_common/components/toggle-button/toggle-button';
@import '_common/components/label-information/label-information';
@import '~@groupClass/styles/group-class-detailed-show';
@import '~@groupClass/styles/coaches';
@import '~@groupClass/styles/periods';
@import '~@groupClass/styles/group-class-edit';
@import '~@groupClass/styles/coach-table';
@import '~@groupClass/styles/period-table';
@import '~@groupSend/styles/group-class-send-message';
@import '~@groupClass/styles/group-class-admin';
@import '~@groupClass/styles/group-class-visitor';
@import '~@search/styles/search-banner';
@import '~@search/styles/search-visitor';
@import '~@banner/styles/trial-banner';
@import '~@banner/styles/trial-banners';
@import '~@banner/styles/header-banner';
@import '~@groupClass/styles/group-classes';
@import '~@layout/components/getphy-logo/getphy-logo';
@import '~@layout/components/siel-bleu-logo/siel-bleu-logo';
@import '~@layout/components/siek-bleu-logo-group-classes/siel-bleu-logo-group-classes';
@import "~@people/styles/people";

/* TAGS STYLES */
body {
  @include scroll-bar;

  &.fixed {
    // used to remove body scroll when mobile menu visible in order to remove
    // double scrolling on iOS Safari
    overflow: hidden;
    position: fixed;
  }
  &.fixed-edge {
    @include touch {
      overflow: hidden;
      position: fixed;
    }
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.hidden {
  display: none;
}

h1 {
  color: $getphy-secondary;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.45rem;
  @include mobile {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
}

h2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

h3 {
  color: $color-black;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

/* GENERIC CLASSES STYLES */
.getphy-site {
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
}

.footer {
  z-index: 5;
  .icon-globe {
    fill: $getphy-secondary;
    width: 1rem;
  }
}

.icon-burger-menu {
  height: 4rem;
  stroke: $color-gray-chalk;
  width: 2rem;
}

.page {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-270 {
  transform: rotate(270deg);
}
