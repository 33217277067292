.label-information-title {
  color: $getphy-label-text;
  display: inline-block;
  font-weight: 700;
}

.link {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: .9;
  }

}

.label-information-value {
  color: $getphy-main-text;
  font-weight: 400;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
}
