.time-picker-container {
  margin-bottom: 0.75rem;

  .time-picker {
    border: 1px solid $getphy-secondary-transparent;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
    user-select: none;

    .time-picker-hours {
      display: flex;
      flex-direction: column;
    }

    .time-picker-minutes {
      display: flex;
      flex-direction: column;
    }

    .time-picker-value {
      align-items: center;
      display: flex;
      height: 26px;
      margin: 0 auto;
    }

    &.time-picker-border-error {
      border-color: $getphy-error;
    }

    .icon-chevron {
      box-sizing: content-box;
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      height: 16px;
      padding: 8px;
      width: 16px;
    }
  }
}
